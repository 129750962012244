.aboutPageContentWrap {
  p {
    margin: 1em 0;
    font-size: 1.2em;
    line-height: 1.4em;
  }

  video {
    max-width: 500px;
    /* padding: 3em 1em; */
    padding-top: 1em;
    padding-bottom: 2em;
  }

  .aboutPageHeaderWrap {
    background: var(--hwu-black);

    .textOverlay {
      top: 8em;
    }
  }

  @media (min-width:600px) {
    .aboutPageHeaderWrap {
      section {
        padding: 250px;
        /* background-position: center; */
      }
    }
  }
}