* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

*::selection {
  background: #df80034b;
}

:root {
  /* == site colors */
  --hwu-white: #f6f6f6;
  --hwu-orange: #df7f03;
  --hwu-hover-orange: #af6910;
  --hwu-black: #282c34;
  /* // site colors */
}

body {
  background-color: var(--hwu-white);
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  main {
    padding-top: 4.2em;
  }

  /* == HWU Global Styles */
  p {
    color: #000;
    a {
      color: var(--hwu-orange);
    }

    a:hover {
      color: var(--hwu-hover-orange);
    }
  }

  a {
    text-decoration: none;
    color: #fff;
  }
  
  a:hover {
    color: #df7f03;
  }

  .hwuMainWrap {
    margin: 0 auto;
    box-sizing: border-box;
    width: 100%;

    .hwuContentWrap {
      max-width: 1400px;
      margin: auto;
    }
  }

  .hwu-mfdoom {
    text-transform: uppercase;
  }

  .hwu-hide {
    display: none;
  }

  .hwu-show {
    display: none;
  }

  .hwuDarkSubmitButton {
    background-color: #282c34;
    color: #fff;
    border: none;
    padding: 0.5em;
    text-align: center;
    text-decoration: none;
    font-size: 1.4em;
    margin-bottom: 2em;
    cursor: pointer;
    font-weight: bold;
    width: 100%;
    height: 2.5em;
  }

  .hwuDarkSubmitButton:hover {
    background-color: #282c34c2;
  }

  .hwuLightSubmitButton {
    background-color: #df7f03;
    color: #fff;
    border: none;
    padding: 0.5em;
    text-align: center;
    text-decoration: none;
    font-size: 1.4em;
    margin-bottom: 1.5em;
    cursor: pointer;
    font-weight: bold;
    width: 100%;
    height: 2.5em;
  }

  .hwuLightSubmitButton:hover {
    background-color: #af6910;
  }
  /* // HWU Global Styles */

  /* == TOP */
  .App-header {
    /* background-color: #282c34; */
    font-size: calc(10px + 1vmin);
    color: white;
    /* padding: 1.5em 0; */
    /* font-weight: 700; */
  }
  /* // TOP */

  /* == MIDDLE */
  /* // MIDDLE */

  /* == BOTTOM */
  /* // BOTTOM */

  .hwuSubmitButton {
    background-color: #df7f03;
    border: none;
    color: white;
    padding: 0.5em;
    text-align: center;
    text-decoration: none;
    /* display: inline-block; */
    font-size: 16px;
    margin-bottom: 2em;
    cursor: pointer;
    width: 53%;
    /* text-transform: uppercase; */
    font-weight: bold;
  }
  
  .hwuSubmitButton:hover {
    background-color: #af6910;
  }

  .textOverlay .hwuSubmitButton {
    width: 10em;
    text-transform: uppercase;
  }
}

.hwuMainContentTextWrap {
  margin: auto;
  text-align: left;
  margin-bottom: 2em;
  line-height: 1.4em;
  font-size: 1.2em;
}

.hwuVideoWrap {
  background-color: var(--hwu-black);
}

.hwuVideoWrapText {
  text-align: left;
  margin: auto;
  padding-top: 1em;

  p {
    color: var(--hwu-white);
  }
}
/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  font-size: calc(8px + 1vmin);
  color: white;
  padding: 1.5em 0;
  font-weight: 700;
} */

.loginSection {
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;

  .loginContent {
    margin: 3em 0;

    .loginCardContent {
      background-color: #fff;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
      transition: 0.3s;
      margin: 0 1.5em;
      border-top: solid 10px #df7f03;
      border-radius: 3px;

      .loginCardTitle {
        background-color: #282c34;
        color: #fff;
        text-align: center;
        padding: 1em 0;
        margin-bottom: 4em;
      }

      .signupFormWrap {
        width: 90%;
        margin: auto;
        text-align: center;
      }
    }

    .loginCardContent:hover {
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    }
  }
}

.hwuMainContentWrap {
  display: flex;
}

#hwuBackgroundVideo {
  pointer-events: none;
  filter: brightness(0.6);
  object-fit: cover;
  height: 88vh;
  position: relative;
  z-index: -99;
  width: 100%;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.textOverlay {
  position: absolute;
  left: 0;
  top: 68%;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
  color: #fff;
  font-size: 2rem;
  /* opacity: 0.8; */
  line-height: 1.8;
}

.hwuTosPageWrap {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 20em;
  line-height: 2.5em;
}

.hwuMainContent {
  margin: 2em auto 0;
  padding: 0 2em;
  text-align: center;
}

.hwuMainContent .hwuSubmitButton {
  width: 16em;
  text-transform: uppercase;
}

/* .App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* .hwuDivContent {
  margin: 0 2em;
} */

/* .hwuDivContentWrap {
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
  width: 100%;

  .hwuDivContent {
    margin: 0 2em;
  }
} */

footer {
  margin-top: 100px;
  margin-top: auto;
  background-color: #282c34;
  color:#fff;
  font-size: calc(14px + 1vmin);

  .hwuFooterBottomWrap {
    background-color: #df7f03;
  }
}

.hwuFooterContent {
  display: flex;
  justify-content: space-around;
  margin: 1em 0;
  text-align: center;
}

.hwuFooterBottomWrap {
  text-align: center;
  padding: 0.5em;
  font-size: 0.8em;
}

.hwuSubmitButton {
  a {
    text-decoration: none;
    color: #fff;
  }
}

.weatherDiv {
  text-align: center;
  margin-top: 2em;

  .weatherInput, .hwuSubmitButton {
    width: 90%;
    margin: 1em 0;
  }

  .weatherInput {
    height: 2em;
  }

  h3 {
    margin: 1em 0;
  }
}

.hwuProfileWrap {
  margin-top: 2em;
}

/* == Desktop only styles */
@media (min-width:600px) {
  .hwuMainContentTextWrap {
    width: 60%;
  }

  .loginCardContent {
    width: 50%;
    margin: auto !important;
  }

  .textOverlay {
    font-size: 3rem;
    line-height: 1.4em;
  }

  .weatherDiv {
    .weatherInput, .hwuSubmitButton {
      width: 33%;
    }
  }
}
/* // Desktop only styles */