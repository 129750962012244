nav {
  position: fixed;
  z-index: 300;
  /* background-color: #282c34; */

  .navWrap {
    /* display: flex;
    justify-content: space-between; */
    /* padding: 1.5em 0; */
    background-color: #282c34;

    .hwuNav {
      display: flex;
      justify-content: space-between;
      margin: 0 2em;
      padding: 20px 0;
    }
  
    .navLogoWrap {
      white-space: nowrap;
  
      h1, ul {
        display: inline-block;
      }
  
      span {
        font-size: 0.9em;
      }
    }
  
    .navContentSection {
      margin: auto auto auto 1em;
    }
  
    .navLoginSection {
      margin: auto 0;
    }
  
    .navContentSection, .navLoginSection  {
      li {
        display: inline-block;
        margin: 0 1em;
      }
    }

  }
  
  .mobileNavMenuList {
    width: 100%;
    z-index: 111;
    background-color: #282c34e3;
    padding: 1.5em 0;
  
    ul {
      list-style-type: none;
      font-size: 1.8em;
      line-height: 2em;
      margin: 0 1.1em 0;
      text-align: left;
      height: 200vw;
    }
  }
  
  /* == Mobile Styles */
  @media (max-width: 768px) {
    .navLogoWrap {
      font-size: calc(12px + 1vmin);
    }
    .navContentSection {
      display: none;
    }
  
    .navLoginSection {
      display: none;
    }
  
    #menuIcon {
      display: inline-block;
      /* padding: 10px; */
      /* padding: 6px 10px; */
    }
  
    #menuIcon .menuBar {
      margin: 5px 0;
      width: 25px;
      height: 3px;
      background: #fff;
      border-radius: 1.5px;
    }
  
    #menuIcon.open .menuBar:first-child {
      -webkit-transform: translateY(8px) rotate(45deg);
      -ms-transform: translateY(8px) rotate(45deg);
      transform: translateY(8px) rotate(45deg);
    }
  
    #menuIcon.open .menuBar:nth-child(2) {
      opacity: 0;
    }
  
    #menuIcon.open .menuBar:last-child {
      -webkit-transform: translateY(-8px) rotate(-45deg);
      -ms-transform: translateY(-8px) rotate(-45deg);
      transform: translateY(-8px) rotate(-45deg);
    }
  }
  /* == Mobile Styles */
  
  /* == Desktop Styles */
  @media (min-width: 767px) {
    .mobileNavMenuList {
      display: none;
    }
  }
  /* // Desktop Styles */
}

